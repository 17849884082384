.remove_filter {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  line-height: 14px;
}

.remove_filter span.info {
  opacity: 0;
  position: absolute;
  top: 0px;
  transform: translateX(-100%);
  pointer-events: none;
  transition: all 0.3s;
  white-space: nowrap;
  font-size: 11px;
}

.remove_filter:hover span.info {
  transform: translateX(calc(-100% - 20px));
  opacity: 1;
}

.gx-card-metrics .ant-card-head-title.ant-card-head-title {
  text-transform: none;
}

.gx-card div.ant-card-head-title {
  font-size: 12px;
}

.center-box {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.center-box > div {
  width: 100%;
}
